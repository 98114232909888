import { $EventBus } from '@/main';
import { mapActions, mapState } from 'vuex';
import { registerStatus } from '../../../../../../store/services/register-status';
import { isDraft, getStatus } from '../../../../../../store/services/register-status';
import textTransform from '@/helpers/textTransform.js';
import moment from 'moment';

export default {
	name: 'RegistersList',
	data: () => ({
		title: '',
		filters: {
			status: '',
			q: '',
			start_date: null,
			end_date: null,
			pagination: 1,
			per_page: process.env.VUE_APP_PAGINATION_PER_PAGE || 10,
			page: 1,
		},
		dateRange: {},
		breadcrumbs: [],
		headers: [],
		createLinkDocument: null,
		registerStatus: [
			...[{ label: 'Todos los estados', value: '' }],
			...registerStatus,
		],
		loading: false,
	}),
	computed: {
		startDate() {
			return this.filters.start_date
				? this.formatDate(this.filters.start_date)
				: '';
		},
		endDate() {
			return this.filters.end_date ? this.formatDate(this.filters.end_date) : '';
		},
		inspection_code() {
			return this.$route?.params?.id || null;
		},
		inspection() {
			return this.$store.state.register.registers_inspection || null;
		},
		...mapState('register', ['registers', 'registers_pagination']),
	},
	created() {
		this.loadData(this.inspection_code);
	},
	mounted() {
		this.createLinkDocument = this.$route.path + '/create';
		$EventBus.$emit('showSubNav', false);
	},
	methods: {
		...mapActions('register', ['list', 'pdf', 'axiosCancellation', 'cleanRegisters']),
		...mapActions('inspection', ['find']),

		textTransform,

		getData() {
			this.loading = true;
			let filters = { ...this.filters };
			if ((filters?.q || '').length < 2) {
				delete filters.q;
			}
			this.list({
				success: () => {
					this.loading = false;
				},
				fail: () => {
					this.loading = false;
				},
				id: this.inspection?.format,
				filters: filters,
			});
		},
		getInspection(id) {
			this.loading = true;
			this.list({
				success: () => {
					this.setBreadCrumbs();
					this.setHeaders();
					this.loading = false;
				},
				fail: () => {
					this.loading = false;
				},
				id,
				filters: this.filters,
			});
		},
		getLinkPdf(id) {
			let link = '';
			this.pdf({
				success: (response) => {
					link = response;
				},
				fail: this.handleFail,
				id: id,
			});
			return link;
		},
		goRegisterView(id) {
			this.$router.push('/dashboard/inspections/registers/' + id);
		},
		setBreadCrumbs() {
			this.breadcrumbs = [
				{
					text: 'Inspecciones',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'InspectionsList',
					},
				},
				{
					text: this.inspection ? textTransform(this.inspection.name) : '',
					disabled: true,
					href: '/',
				},
			];
		},
		listenDatePicker(val) {
			this.filters.start_date = val[0] ? moment(val[0]).format('YYYY-MM-DD') : '';
			this.filters.end_date = val[1] ? moment(val[1]).format('YYYY-MM-DD') : '';
			this.getData();
		},
		formatDate(date) {
			if (!date) return null;
			const [year, month, day] = date.split('-');
			return `${day}/${month}/${year}`;
		},
		formatDateString(date) {
			let dateString = '';
			if (date) {
				let d = date.toString().substring(0, 10).split('-');
				dateString = `${d[2]}-${d[1]}-${d[0]}`;
			}
			return dateString;
		},
		changeFilter() {
			this.getData();
			this.menu = false;
		},
		setHeaders() {
			if (this.inspection) {
				this.headers = [
					{
						text: 'Fecha',
						value: 'date',
						sortable: false,
						class: 'primary--text',
					},
					{
						text: 'Área/Taller/Estación',
						value: 'area_workshop_station',
						align: 'null',
						sortable: false,
						class: 'primary--text text-center',
					},
					{
						text: 'Estado',
						value: 'status',
						align: 'center',
						sortable: false,
						class: 'primary--text',
					},
					{
						text: 'Acciones',
						value: 'show',
						align: 'right',
						sortable: false,
						class: 'primary--text',
					},
				];
			}
		},
		loadData(id) {
			if (id) {
				this.getInspection(id);
				// TODO: remove this.getData();
			}
		},
		clearEndDate() {
			this.filters.end_date = null;
			this.changeFilter();
		},
		clearStartDate() {
			this.filters.start_date = null;
			this.changeFilter();
		},
		valIsDraft(value) {
			return isDraft(value);
		},
		getStatusRegister(value) {
			return getStatus(value);
		},
		listenTextSearch() {
			if ((this.filters.q || '').length > 1 || this.filters.q == '') {
				this.getData();
			}
		},
	},
	destroyed() {
		this.cleanRegisters();
	},
	components: {},
};
